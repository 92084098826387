import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import projectReducer from "./MultiUnit/projectReducer";
import testReducer from "./MultiUnit/testReducer";
import testerReducer from "./MultiUnit/testerReducer";
import reportReducer from "./MultiUnit/reportReducer";
import summaryReducer from "./MultiUnit/summaryReducer";
import singleTestReducer from "./SingleUnit/testReducer";
import singleProjectReducer from "./SingleUnit/projectReducer";
import singleTesterReducer from "./SingleUnit/testerReducer";
import singleReportReducer from "./SingleUnit/reportReducer";
import singleClientReducer from "./SingleUnit/clientReducer";

export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  projectReducer,
  testReducer,
  testerReducer,
  reportReducer,
  summaryReducer,
  singleTestReducer,
  singleProjectReducer,
  singleTesterReducer,
  singleReportReducer,
  singleClientReducer,
});
