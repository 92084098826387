// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT_REQUEST = "AUTH_SIGN_OUT_REQUEST";
export const AUTH_SIGN_OUT_SUCCESS = "AUTH_SIGN_OUT_SUCCESS";
export const AUTH_SIGN_OUT_FAILURE = "AUTH_SIGN_OUT_FAILURE";
export const AUTH_VERIFY_CODE_REQUEST = "AUTH_VERIFY_CODE_REQUEST";
export const AUTH_VERIFY_CODE_SUCCESS = "AUTH_VERIFY_CODE_SUCCESS";
export const AUTH_VERIFY_CODE_FAILURE = "AUTH_VERIFY_CODE_FAILURE";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_CHANGE_PWD_REQUEST = "AUTH_CHANGE_PWD_REQUEST";
export const AUTH_CHANGE_PWD_SUCCESS = "AUTH_CHANGE_PWD_SUCCESS";
export const AUTH_CHANGE_PWD_FAILURE = "AUTH_CHANGE_PWD_FAILURE";
export const AUTH_UPDATE_ATTRIBUTE_REQUEST = "AUTH_UPDATE_ATTRIBUTE_REQUEST";
export const AUTH_UPDATE_ATTRIBUTE_SUCCESS = "AUTH_UPDATE_ATTRIBUTE_SUCCESS";
export const AUTH_UPDATE_ATTRIBUTE_FAILURE = "AUTH_UPDATE_ATTRIBUTE_FAILURE";
export const AUTH_RESEND_SIGNUP_REQUEST = "AUTH_RESEND_SIGNUP_REQUEST";
export const AUTH_RESEND_SIGNUP_SUCCESS = "AUTH_RESEND_SIGNUP_SUCCESS";
export const AUTH_RESEND_SIGNUP_FAILURE = "AUTH_RESEND_SIGNUP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";
export const AUTH_FORGOT_PASSWORD_REQUEST = "AUTH_FORGOT_PASSWORD_REQUEST";
export const AUTH_FORGOT_PASSWORD_SUCCESS = "AUTH_FORGOT_PASSWORD_SUCCESS";
export const AUTH_FORGOT_PASSWORD_FAILURE = "AUTH_FORGOT_PASSWORD_FAILURE";

// Company (mitigator)
export const COMPANY_INFO_CREATE_REQUEST = "COMPANY_INFO_CREATE_REQUEST";
export const COMPANY_INFO_CREATE_SUCCESS = "COMPANY_INFO_CREATE_SUCCESS";
export const COMPANY_INFO_CREATE_FAILURE = "COMPANY_INFO_CREATE_FAILURE";
export const COMPANY_INFO_UPDATE_REQUEST = "COMPANY_INFO_UPDATE_REQUEST";
export const COMPANY_INFO_UPDATE_SUCCESS = "COMPANY_INFO_UPDATE_SUCCESS";
export const COMPANY_INFO_UPDATE_FAILURE = "COMPANY_INFO_UPDATE_FAILURE";
export const COMPANY_INFO_GET_REQUEST = "COMPANY_INFO_GET_REQUEST";
export const COMPANY_INFO_GET_SUCCESS = "COMPANY_INFO_GET_SUCCESS";
export const COMPANY_INFO_GET_FAILURE = "COMPANY_INFO_GET_FAILURE";

// Order
export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAILURE = "ORDER_CREATE_FAILURE";
export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST";
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS";
export const ORDER_DETAIL_FAILURE = "ORDER_DETAIL_FAILURE";
export const ORDER_DETAIL_BY_QTY_REQUEST = "ORDER_DETAIL_BY_QTY_REQUEST";
export const ORDER_DETAIL_BY_QTY_SUCCESS = "ORDER_DETAIL_BY_QTY_SUCCESS";
export const ORDER_DETAIL_BY_QTY_FAILURE = "ORDER_DETAIL_BY_QTY_FAILURE";
export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILURE = "ORDER_LIST_FAILURE";
export const REPORT_ISSUE_REQUEST = "REPORT_ISSUE_REQUEST";
export const REPORT_ISSUE_SUCCESS = "REPORT_ISSUE_SUCCESS";
export const REPORT_ISSUE_FAILURE = "REPORT_ISSUE_FAILURE";

// SUBSCRIPTION
export const SUBSCRIPTION_CREATE_REQUEST = "SUBSCRIPTION_CREATE_REQUEST";
export const SUBSCRIPTION_CREATE_SUCCESS = "SUBSCRIPTION_CREATE_SUCCESS";
export const SUBSCRIPTION_CREATE_FAILURE = "SUBSCRIPTION_CREATE_FAILURE";
export const SUBSCRIPTION_DETAIL_REQUEST = "SUBSCRIPTION_DETAIL_REQUEST";
export const SUBSCRIPTION_DETAIL_SUCCESS = "SUBSCRIPTION_DETAIL_SUCCESS";
export const SUBSCRIPTION_DETAIL_FAILURE = "SUBSCRIPTION_DETAIL_FAILURE";
export const SUBSCRIPTION_LIST_REQUEST = "SUBSCRIPTION_LIST_REQUEST";
export const SUBSCRIPTION_LIST_SUCCESS = "SUBSCRIPTION_LIST_SUCCESS";
export const SUBSCRIPTION_LIST_FAILURE = "SUBSCRIPTION_LIST_FAILURE";

// Payment
export const PAYMENT_INTENT_CREATE_REQUEST = "PAYMENT_INTENT_CREATE_REQUEST";
export const PAYMENT_INTENT_CREATE_SUCCESS = "PAYMENT_INTENT_CREATE_SUCCESS";
export const PAYMENT_INTENT_CREATE_FAILURE = "PAYMENT_INTENT_CREATE_FAILURE";

// Cognito attributes
export const CUSTOM_COMPANY_EMAIL = "custom:companyEmail";
export const CUSTOM_GROUP = "custom:group";

// local storage(LS) keys
export const LS_EMAIL = "0xaccountId";
export const LS_NAME = "0xuserId";
export const LS_ACCESS_SOURCE = "accessSource";
export const LS_ACCESS_TOKEN = "accessToken";

// Project
export const PROJECT_LOADING = "MU/project/loading";
export const PROJECT_LOADING_DISABLE = "MU/project/loadingDisable";
export const CREATE_PROJECT = "MU/CREATE_PROJECT";
export const RESET_SELECTED_PROJECT = "MU/RESET_SELECTED_PROJECT";
export const RETRIEVE_PROJECT_LIST = "MU/RETRIEVE_PROJECT_LIST";
export const RETRIEVE_PROJECT_DETAILS = "MU/RETRIEVE_PROJECT_DETAILS";
export const UPDATE_PROJECT = "MU/UPDATE_PROJECT";
export const DELETE_PROJECT = "MU/DELETE_PROJECT";
export const RESET_PROJECT_LIST = "MU/RESET_PROJECT_LIST";

// Test
export const TEST_LOADING = "MU/test/loading";
export const TEST_LOADING_DISABLE = "MU/test/loadingDisable";
export const RETRIEVE_TEST_LIST = "MU/RETRIEVE_TEST_LIST";
export const RETRIEVE_TEST_INFO = "MU/RETRIEVE_TEST_INFO";
export const DELETE_TEST = "MU/DELETE_TEST";
export const CURRENT_TEST = "MU/CURRENT_TEST";
export const RESET_TEST_LIST = "MU/RESET_TEST_LIST";
export const TEST_FILTER = "MU/TEST_FILTER";
export const UPDATE_MEASUREMENT_INFO = "MU/UPDATE_MEASUREMENT_INFO";
export const MEASUREMENT_INFO_LOADING = "MU/MEASUREMENT_INFO_LOADING";
export const MEASUREMENT_INFO_LOADING_DISABLE =
  "MU/MEASUREMENT_INFO_LOADING_DISABLE";
export const TEST_SORT = "MU/test/sort";
export const SUMMARY_TEST = "MU/SUMMARY_TEST";

// Tester
export const TESTER_LOADING = "MU/tester/loading";
export const TESTER_LOADING_DISABLE = "MU/tester/loadingDisable";
export const RETRIEVE_TESTER_LIST = "MU/RETRIEVE_TESTER_LIST";
export const ADD_NEW_TESTER = "MU/ADD_NEW_TESTER";
export const UPDATE_TESTER = "MU/UPDATE_TESTER";
export const RESEND_TESTER = "MU/RESEND_TESTER";
export const DELETE_TESTER = "MU/DELETE_TESTER";
export const RESET_TESTERS = "MU/RESET_TESTERS";
export const TESTER_SORT = "MU/tester/sort";

//REPORT
export const REPORT_LOADING = "MU/report/loading";
export const REPORT_LOADING_DISABLE = "MU/report/loadingDisable";
export const RETRIEVE_REPORT_LIST = "MU/RETRIEVE_REPORT_LIST";
export const RETRIEVE_REPORT_LIST_FILTER = "MU/RETRIEVE_REPORT_LIST_FILTER";
export const RETRIEVE_REPORT_DETAIL = "MU/RETRIEVE_REPORT_DETAIL";
export const DELETE_REPORT = "MU/DELETE_REPORT";
export const RESET_REPORTS = "MU/RESET_REPORTS";
export const REPORT_SORT = "MU/report/sort";

// Summary
export const SUMMARY_LOADING = "MU/summary/loading";
export const SUMMARY_LOADING_DISABLE = "MU/summary/loadingDisable";
export const RETRIEVE_SUMMARY_LIST = "MU/RETRIEVE_SUMMARY_LIST";
export const RETRIEVE_SUMMARY_INFO = "MU/RETRIEVE_SUMMARY_INFO";
export const DELETE_SUMMARY = "MU/DELETE_SUMMARY";
export const CURRENT_SUMMARY = "MU/CURRENT_SUMMARY";
export const RESET_SUMMARY_LIST = "MU/RESET_SUMMARY_LIST";
export const SUMMARY_FILTER = "MU/SUMMARY_FILTER";
export const SUMMARY_SORT = "MU/summary/sort";

// SingleUnit Project
export const SU_PROJECT_LOADING = "SU/project/loading";
export const SU_PROJECT_LOADING_DISABLE = "SU/project/loadingDisable";
export const SU_RETRIEVE_PROJECT_DETAILS = "SU/RETRIEVE_PROJECT_DETAILS";
export const SU_UPDATE_PROJECT = "SU/UPDATE_PROJECT";
export const SU_DELETE_PROJECT = "SU/DELETE_PROJECT";
export const SU_RESET_PROJECT_LIST = "SU/RESET_PROJECT_LIST";

// SingleUnit Tests
export const SU_TEST_LOADING = "SU/test/loading";
export const SU_TEST_LOADING_DISABLE = "SU/test/loadingDisable";
export const SU_GENERATE_REPORT_LOADING = "SU/generate/loading";
export const SU_GENERATE_REPORT_LOADING_DISABLE = "SU/generate/loadingDisable";
export const SU_RETRIEVE_TEST_LIST = "SU/RETRIEVE_TEST_LIST";
export const SU_RETRIEVE_TEST_INFO = "SU/RETRIEVE_TEST_INFO";
export const SU_DELETE_TEST = "SU/DELETE_TEST";
export const SU_CURRENT_TEST = "SU/CURRENT_TEST";
export const SU_RESET_TEST_LIST = "SU/RESET_TEST_LIST";
export const SU_TEST_FILTER = "SU/TEST_FILTER";
export const SU_UPDATE_TEST_INFO = "SU/SU_UPDATE_TEST_INFO";
export const SU_UPDATE_MEASUREMENT_INFO = "SU/UPDATE_MEASUREMENT_INFO";
export const SU_MEASUREMENT_INFO_LOADING = "SU/MEASUREMENT_INFO_LOADING";
export const SU_MEASUREMENT_INFO_LOADING_DISABLE =
  "SU/MEASUREMENT_INFO_LOADING_DISABLE";
export const SU_TEST_SORT = "SU/test/sort";
export const SU_GENERATE_REPORT = "SU/GENERATE_REPORT";

// SingleUnit Testers
export const SU_TESTER_LOADING = "SU/tester/loading";
export const SU_TESTER_LOADING_DISABLE = "SU/tester/loadingDisable";
export const SU_RETRIEVE_TESTER_LIST = "SU/RETRIEVE_TESTER_LIST";
export const SU_ADD_NEW_TESTER = "SU/ADD_NEW_TESTER";
export const SU_UPDATE_TESTER = "SU/UPDATE_TESTER";
export const SU_RESEND_TESTER = "SU/RESEND_TESTER";
export const SU_DELETE_TESTER = "SU/DELETE_TESTER";
export const SU_RESET_TESTERS = "SU/RESET_TESTERS";
export const SU_TESTER_SORT = "SU/tester/sort";

// SingleUnit Reports
export const SU_REPORT_LOADING = "SU/report/loading";
export const SU_REPORT_LOADING_DISABLE = "SU/report/loadingDisable";
export const SU_RETRIEVE_REPORT_LIST = "SU/RETRIEVE_REPORT_LIST";
export const SU_RETRIEVE_REPORT_LIST_FILTER = "SU/RETRIEVE_REPORT_LIST_FILTER";
export const SU_RETRIEVE_REPORT_DETAIL = "SU/RETRIEVE_REPORT_DETAIL";
export const SU_DELETE_REPORT = "SU/DELETE_REPORT";
export const SU_RESET_REPORTS = "SU/RESET_REPORTS";
export const SU_REPORT_SORT = "SU/report/sort";

// SingleUnit Clients
export const SU_CLIENT_LOADING = "SU/client/loading";
export const SU_CLIENT_LOADING_DISABLE = "SU/client/loadingDisable";
export const SU_RETRIEVE_CLIENT_LIST = "SU/RETRIEVE_CLIENT_LIST";
export const SU_DELETE_CLIENT = "SU/DELETE_CLIENT";
export const SU_CURRENT_CLIENT = "SU/CURRENT_CLIENT";
export const SU_RESET_CLIENT_LIST = "SU/RESET_CLIENT_LIST";
export const SU_UPDATE_CLIENT_INFO = "SU/UPDATE_CLIENT_INFO";
export const SU_CLIENT_SORT = "SU/client/sort";
