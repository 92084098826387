import * as types from "../../../constants";

const initialState = {
  loading: false,
  summaryList: [],
  currentSummary: {},
  filteredSummaryList: [],
  filterSettings: {
    all: 1,
  },
  filterEnabled: false,
  sort: { order: "desc", orderBy: "generatedDate" },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUMMARY_SORT:
      return {
        ...state,
        sort: { order: action.payload.order, orderBy: action.payload.orderBy },
      };
    case types.SUMMARY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SUMMARY_LOADING_DISABLE:
      return {
        ...state,
        loading: false,
      };
    case types.RETRIEVE_SUMMARY_LIST:
      return {
        ...state,
        summaryList: [...action.payload],
        loading: false,
      };
    case types.RETRIEVE_SUMMARY_INFO:
      return {
        ...state,
        currentSummary: { ...action.payload },
        loading: false,
      };
    case types.CURRENT_SUMMARY:
      return {
        ...state,
        currentSummary: action.payload,
      };
    case types.DELETE_SUMMARY:
      let newSummaryList = [];

      newSummaryList = state.summaryList.filter(
        (summary) => summary.summaryId !== action.payload
      );

      return {
        ...state,
        summaryList: [...newSummaryList],
        loading: false,
      };
    case types.SUMMARY_FILTER:
      if (action.payload.all) {
        return {
          ...state,
          filteredSummaryList: [],
          filterEnabled: false,
          filterSettings: {
            all: 1,
          },
        };
      }

      return {
        ...state,
        filteredSummaryList: [],
        filterEnabled: true,
        filterSettings: { ...action.payload },
      };
    case types.RESET_SUMMARY_LIST:
      return {
        ...state,
        currentSummary: {},
        summaryList: [],
      };
    default:
      return state;
  }
};

export default reducer;
